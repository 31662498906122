@import 'variables';

.Prompt {
  color: $gray--black;
  font-size: 14px;
  font-weight: 400;
}

.PromptHeading {
  color: $gray--black;
  font-size: 16px;
  font-weight: 600;
}
