@import 'variables';

.Container {
  margin-bottom: 2em;
  width: 100%;
}

.Field {
  background-color: $white;
  border: solid 1px $gray;
  border-radius: 2px;
  height: 44px;
  margin-top: 0.5rem;

  ::placeholder {
    color: $gray;
  }
}

.PasswordCriteria {
  list-style-type: none;
  padding-left: 1.5em;

  > li {
    margin-left: 0;
    padding-left: 0;
    position: relative;

    &:before {
      content: '-';
      left: -1em;
      position: absolute;
      top: 0;
    }
  }
}

.PromptHeading {
  color: $gray--black;
  font-size: 16px;
  font-weight: 600;
}

.PromptText {
  color: $gray--black;
  font-size: 14px;
}

.Reset {
  background-color: $blue;
  border-radius: 2px;
  font-size: 14px;
  padding: 10px 17px;
  text-transform: uppercase;
}

.RightAlignedRow {
  flex-direction: row-reverse;
  margin: 0;
  padding-bottom: 32px;
}
