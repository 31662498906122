@import 'variables';

.IconColumn {
  width: 29px;
}

.MessageColumn {
  flex-grow: 1;
  padding: 0 0 0 20px;
}

.MessageContainer {
  display: flex;
  margin-left: -55px;
  margin-right: -55px;
  padding: 50px 0;
}

.Message {
  color: $gray--black;
  font-size: 14px;
  font-weight: 400;
}

.MessageHeading {
  color: $gray--black;
  font-size: 16px;
  font-weight: 600;
}
