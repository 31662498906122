@import 'variables';

.Container {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 $black;
  flex-grow: 1;
  margin: 0 44px;
  max-width: 550px;
  padding: 20px 20px 44px 20px;
}

.Image {
  max-height: 530px;
  object-fit: scale-down;
}
