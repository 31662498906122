@import 'variables';

.FormWrapper {
  margin: 0 -50px;
}

.PasswordCriteria {
  list-style-type: none;
  padding-left: 1.5em;

  > li {
    margin-left: 0;
    padding-left: 0;
    position: relative;

    &:before {
      content: '-';
      left: -1em;
      position: absolute;
      top: 0;
    }
  }
}

.PromptHeading {
  color: $gray--black;
  font-size: 16px;
  font-weight: 600;
}

.PromptText {
  color: $gray--black;
  font-size: 14px;
}

.RightAlignedRow {
  flex-direction: row-reverse;
  margin: 0;
  padding-bottom: 32px;
}
