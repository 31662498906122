.ExclamationIconContainer {
  svg {
    height: 85px;
    margin-bottom: 39px;
    width: 85px;
  }
}

.ErrorText {
  font-size: 24px;
}
