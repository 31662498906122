@import 'variables';

/* stylelint-disable */
/* These selectors are out of order according to Stylelint
   but this ordering needs to be maintained to get the 
   desired styling. */
.TextFieldContainer {
  position: relative;
  width: 100%;

  .Input {
    background-color: transparent;
    border: none;
    height: 44px;
    outline: none;
    padding: 10px 15px;
    width: 100%;

    &::placeholder {
      color: transparent;
    }

    & + .Notch {
      display: flex;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;

      .NotchBefore {
        border-bottom: 1px solid $black;
        height: 100%;
        width: 15px;
      }

      .NotchBetween {
        border-bottom: 1px solid $black;
        height: 100%;
        width: auto;

        label {
          color: $gray;
          transform: translateY(10px);
          transition: transform 0.2s;
        }
      }

      .NotchAfter {
        border-bottom: 1px solid $black;

        flex-grow: 1;
        height: 100%;
      }
    }

    &:not(:placeholder-shown) + .Notch,
    &:focus + .Notch {
      * {
        border-radius: 4px;
      }

      .NotchBefore {
        border: 1px solid $blue;
        border-bottom-right-radius: 0;
        border-right: 0;
        border-top-right-radius: 0;
      }

      .NotchBetween {
        border-bottom: 1px solid $blue;
        border-radius: 0;

        label {
          color: $blue;
          transform: scale(0.8) translateY(-15px);
        }

        &.LabelHidden {
          label {
            display: none;
          }
        }
      }

      .NotchAfter {
        border: 1px solid $blue;
        border-bottom-left-radius: 0;
        border-left: 0;
        border-top-left-radius: 0;
      }
    }

    // This has to be a separate rule to fix the autofill styling on Chrome
    // (when it's a selector on the same rule as the Safari/FF fix neither work...)
    &:-internal-autofill-selected + .Notch {
      * {
        border-radius: 4px;
      }

      .NotchBefore {
        border: 1px solid $blue;
        border-bottom-right-radius: 0;
        border-right: 0;
        border-top-right-radius: 0;
      }

      .NotchBetween {
        border-bottom: 1px solid $blue;
        border-radius: 0;

        label {
          color: $blue;
          transform: scale(0.8) translateY(-15px);
        }

        &.LabelHidden {
          label {
            display: none;
          }
        }
      }

      .NotchAfter {
        border: 1px solid $blue;
        border-bottom-left-radius: 0;
        border-left: 0;
        border-top-left-radius: 0;
      }
    }

    &.Invalid {
      background-color: $orange--light;

      & + .Notch,
      &:not(:placeholder-shown) + .Notch,
      &:focus + .Notch {
        & > * {
          border-color: $orange;
        }

        .NotchBetween {
          label {
            color: $orange;
          }
        }
      }
    }

    &:disabled {
      color: $gray;

      & + .Notch,
      &:not(:placeholder-shown) + .Notch,
      &:focus + .Notch {
        & > * {
          border-color: $gray;
        }

        .NotchBetween {
          label {
            color: $gray;
          }
        }
      }
    }
  }
}

.ErrorText {
  color: $orange;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-align: right;
  width: 100%;
}
/* stylelint-enable */
