.Header {
  padding: 0.75rem 1rem;
  width: 100%;

  * {
    font-family: 'Poppins', sans-serif;
  }
}

.HeaderLogo {
  height: 32px;
  width: 161px;
}
