@import 'variables';

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem 0.8rem;
}

.Dialog {
  max-width: 27rem;
}

.Modal {
  :global(.modal-content) {
    border-radius: 0;
    padding: 0 1rem;
  }
}
