@import 'variables';

.CenterAlignedRow {
  padding: 36px 0 100px 0;
  text-align: center;
}

.PromptHeading {
  color: $gray--black;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0 12px 0;
}

.PromptText {
  color: $gray--black;
  font-size: 14px;
}
