@import 'variables';

.Footer {
  align-items: center;
  color: $black;
  display: flex;
  font-size: 10px;
  padding: 18px;

  p {
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 16px;
  }

  svg {
    max-height: 20px;
    max-width: 60px;
  }
}
