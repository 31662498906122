@import 'variables';

.Children {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

.CenteredChildren {
  justify-content: center;
}

.Container {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.ContainerWithHeader {
  padding-top: 73px;
}

.FitOnScreen {
  height: 100%;

  .Children {
    min-height: 0;
  }

  .Container {
    min-height: 0;
    overflow-y: auto;
  }
}

.Page {
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
