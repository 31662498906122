.AlertContainer {
  min-height: 50px;
}

.Alert {
  left: 0;
  position: absolute;
  width: 100%;

  div:global(.alert) {
    border-radius: 0;
    font-size: 1rem;
    margin: 0;
    padding-right: 25px;
    text-align: center;
  }

  button:global(.close) {
    position: absolute;
  }
}
